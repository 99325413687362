import revive_payload_client_FiF2dE2ZMb from "/usr/src/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.2_eslint@8.55.0_sass@1.69.5_typescript@5.3.2_vite@5.0.6_vue-tsc@1.8.25/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JJb7mHzpju from "/usr/src/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.2_eslint@8.55.0_sass@1.69.5_typescript@5.3.2_vite@5.0.6_vue-tsc@1.8.25/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Qze3lHPFtp from "/usr/src/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.2_eslint@8.55.0_sass@1.69.5_typescript@5.3.2_vite@5.0.6_vue-tsc@1.8.25/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_C18hNY5FnO from "/usr/src/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.2_eslint@8.55.0_sass@1.69.5_typescript@5.3.2_vite@5.0.6_vue-tsc@1.8.25/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_xvUx1efPds from "/usr/src/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.3.2_vue@3.3.10/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_hhK97tQLvo from "/usr/src/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.2_eslint@8.55.0_sass@1.69.5_typescript@5.3.2_vite@5.0.6_vue-tsc@1.8.25/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_HW8DLu7R8N from "/usr/src/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.8.2_vue@3.3.10/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/usr/src/app/.nuxt/formkitPlugin.mjs";
import composition_E2MWZjZww4 from "/usr/src/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_vue@3.3.10/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_pzN5M7haqe from "/usr/src/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_vue@3.3.10/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_LryggnJjrZ from "/usr/src/app/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.10_typescript@5.3.2_vue@3.3.10/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import shopware_plugin_txRT4f0fRY from "/usr/src/app/.nuxt/runtime/shopware.plugin.mjs";
import chunk_reload_client_jpxNeYwDDS from "/usr/src/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.2_eslint@8.55.0_sass@1.69.5_typescript@5.3.2_vite@5.0.6_vue-tsc@1.8.25/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_dObysIEGs6 from "/usr/src/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.2_eslint@8.55.0_sass@1.69.5_typescript@5.3.2_vite@5.0.6_vue-tsc@1.8.25/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import ssr_plugin_tmctN764JA from "/usr/src/app/node_modules/.pnpm/@vueuse+nuxt@10.7.0_nuxt@3.8.2_vue@3.3.10/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import index_QGt4Sg6sLw from "/usr/src/app/src/plugins/animation/index.ts";
import apollo_persisted_queries_link_OwM6Rdu4ne from "/usr/src/app/src/plugins/apollo-persisted-queries-link.ts";
import googleRecaptcha_mu1rNDPpmz from "/usr/src/app/src/plugins/googleRecaptcha.ts";
export default [
  revive_payload_client_FiF2dE2ZMb,
  unhead_JJb7mHzpju,
  router_Qze3lHPFtp,
  payload_client_C18hNY5FnO,
  plugin_vue3_xvUx1efPds,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hhK97tQLvo,
  plugin_HW8DLu7R8N,
  formkitPlugin_pZqjah0RUG,
  composition_E2MWZjZww4,
  i18n_pzN5M7haqe,
  plugin_LryggnJjrZ,
  shopware_plugin_txRT4f0fRY,
  chunk_reload_client_jpxNeYwDDS,
  check_outdated_build_client_dObysIEGs6,
  ssr_plugin_tmctN764JA,
  index_QGt4Sg6sLw,
  apollo_persisted_queries_link_OwM6Rdu4ne,
  googleRecaptcha_mu1rNDPpmz
]