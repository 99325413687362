export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"property":"theme-color","content":"#ffffff"},{"property":"msapplication-TileColor","content":"#e2f5ff"},{"name":"apple-mobile-web-app-title","content":"Careum"},{"property":"og:title","content":"Careum"},{"property":"og:site_name","content":"Careum"},{"property":"fb:app_id","content":"966242223397117"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#18246f"}],"style":[],"script":[{"innerHTML":"\n            // Define dataLayer and the gtag function.\n            window.dataLayer = window.dataLayer || [];\n            function gtag(){dataLayer.push(arguments);}\n            // Default ad_storage to 'denied' as a placeholder\n            gtag('consent', 'default', {\n              ad_storage: 'denied',\n              analytics_storage: 'denied',\n              functionality_storage: 'granted',\n              personalization_storage: 'denied',\n              security_storage: 'granted',\n              ad_user_data: 'denied',\n              ad_personalization: 'denied'\n            });\n          ","type":"text/javascript"},{"src":"https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js","type":"text/javascript","data-domain-script":"50602afd-7ee1-4a66-a9e0-a08b7e48930a"},{"innerHTML":"function OptanonWrapper() { }","type":"text/javascript"}],"noscript":[],"title":"Careum"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'